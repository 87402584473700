







import { Component } from 'vue-property-decorator'
import VueBase from '@/VueBase'

@Component({ name: 'SettingIndex' })
export default class SettingIndex extends VueBase {
  hiddenMap = {
    sensitive: true,
    strategy: true,
    template: true,
    agentConfig: true,
    jira: true,
    chandao: true,
    gitlab: true,
    jenkins: true,
  }
  menuActive = {
    [this.$t('menu.strategyManage') as any]: false,
    [this.$t('menu.OrganizationManagement') as any]: false,
  }

  menuGroup: any = {
    集成: ['setting'],
    [this.$t('menu.strategyManage') as any]: [
      'strategyManage',
      'templateManage',
      'hookRule',
      'sensitiveManage',
    ],
    项目模版: ['projectTemplate'],
    [this.$t('menu.OrganizationManagement') as any]: [
      'talentList',
      'departmentList',
      'userSetting',
      'roleSetting',
    ],
    密码修改: ['changePassword'],
    商业授权: ['license'],
    品牌配置: ['changeLogo'],
    // 状态监控: ['StatusMonitoring'],
    系统配置: ['systemSettings'],
    操作日志: ['logManage'],
    关于MicroView: ['about'],
  }

  private curModule(name: string) {
    const routeMap = {
      sensitive: 'sensitiveManage',
      strategy: 'strategyManage',
      template: 'templateManage',
      agentConfig: 'sysInfo',
      jira: 'integration',
      chandao: 'integration',
      gitlab: 'integration',
      jenkins: 'integration',
    }
    const route: any = this.$route
    return routeMap[route.name] === name || route.name === name
  }

  RouterMap = {}

  get Routers() {
    const R = this.$store.getters.routers[0].children.filter((i: any) => {
      return i.name === 'setting'
    })[0]
    if (R) {
      return R.children
    } else {
      return []
    }
  }
  created() {
    this.Routers.forEach((route: any) => {
      this.RouterMap[route.name] = route
    })

    console.log('this.RouterMap', this.RouterMap)
  }
  get userInfo(): { username: string } {
    return this.$store.getters.userInfo
  }
}
